import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { InputSearch } from "shared/Components";
import { Funciones } from "services/funciones";
import { Alert, Confirm } from "shared/Alert";
import withRouter from "services/withRouter";
import ReactExport from "react-export-excel-ext";
import { NavLink } from "react-router-dom";
import Loading from "shared/Loading";
import Menu from "./components/Menu";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

const _border = {
    top: { style: "thin", color: { rgb: "ff000000" } },
    bottom: { style: "thin", color: { rgb: "ff000000" } },
    left: { style: "thin", color: { rgb: "ff000000" } },
    right: { style: "thin", color: { rgb: "ff000000" } },
};

const _align_rigth = { horizontal: "right", vertical: "center", wrapText: false };
const _align_center = { horizontal: "center", vertical: "center", wrapText: false };

const _heads = {
    fill: { patternType: "solid", fgColor: { rgb: "ffb7e1cd" } },
    font: { sz: "12", bold: false, color: { rgb: "ffff0000" } },
    alignment: _align_center,
    border: _border
};

const _txt = { font: { sz: "10.5" }, border: _border };

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Devolutions = (router) => {
    const { navigate, location, params } = router;
    const { setTitle, media_movil, setDataUser } = useMedia();
    const [fileName, setFileName] = React.useState("Todos las guías de " + params.name.replace(/_/g, ' '));
    const [mensaje, setMensaje] = React.useState("Cargando guías...");
    const [titulo, setTitulo] = React.useState("Pedidos de " + params.name.replace(/_/g, ' '));
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [selecToExcel, setSelecToExcel] = React.useState(false);
    const [seleccionados, setSeleccionados] = React.useState([]);
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [currentQuery, setCurrentQuery] = React.useState(7);
    const [showLazy, setShowLazy] = React.useState(true);
    const [criterio, setCriterio] = React.useState("");
    const [dataList, setDataList] = React.useState([]);
    const [exceList, setExceList] = React.useState([]);



    const BotonDescargar = ({ enable }) => {
        let _btn = <button className="btn btn_with_icon _left unable"><Iconos.ExcelDownload />Descargar excel</button>;

        if (showLoading) {
            _btn = <button className="btn btn_with_icon _left"><Iconos.Loading />Cargando info...</button>;
        } else if (enable) {
            _btn = <button className="btn btn_with_icon _left"><Iconos.ExcelDownload />Descargar Excel</button>;
        }

        return _btn;
    }

    const HojaDeCalculo = () => {
        const code = new Date().getTime().toString(); 

        const pedidos = [
            {
                columns: [
                    { value: "Guía", style: _heads, width: 10 },
                    { value: "Valor", style: _heads, width: 10 },
                    { value: "Código", style: _heads, width: 15 },
                    { value: "Envío", style: _heads, width: 8 },
                    { value: "Pedido", style: _heads, width: 8 },
                    { value: "Fecha entregado", style: _heads, width: 18 },
                    { value: "Estado", style: _heads, width: 0 },
                ],
                data: exceList.map(item => {
                    let _info = JSON.parse(item.info);
                    let _carrier = JSON.parse(item.carrier);
                    let _c_estado = _carrier.conciliacion?.estado ?? 0;
                    let fecha_entrega = new Date(_carrier.fecha_entregado);
                    let _estado = _c_estado == 3 ? "Devuelto" : _c_estado == 1 ? "Conciliado" : "Pendiente";

                    return [
                        { value: item.guia ?? "", style: _txt },
                        { value: _info.valor ?? "", style: { ..._txt, alignment: _align_rigth } },
                        { value: code, style: _txt },
                        { value: "0", style: _txt },
                        { value: _info.numero ?? "", style: _txt },
                        { value: format(fecha_entrega.getDate()) + "/" + meses[fecha_entrega.getMonth()] + "/" + fecha_entrega.getFullYear() + " " + format(fecha_entrega.getHours()) + ":" + format(fecha_entrega.getMinutes()), style: { ..._txt, alignment: _align_center } },
                        { value: _estado, style: { ..._txt, alignment: _align_center } },
                    ];
                })
            }
        ];

        return <>
            {(exceList.length == 0) ?
                <BotonDescargar enable={false} />
                :
                <ExcelFile filename={fileName} element={<BotonDescargar enable={true} />}>
                    <ExcelSheet dataSet={pedidos} name={params.name.length > 18 ? "Guías de " + params.name.replace(/_/g, ' ').substring(0, 18) + "..." : "Guías de " + params.name.replace(/_/g, ' ')} />
                </ExcelFile>
            }
        </>;
    }

    const Filter = () => {
        const [showFilter, setShowFilter] = React.useState(false);
        const [pos, setPos] = React.useState({ top: 0, right: 0 });


        const handleSelct = (_opcion) => {
            document.body.style.overflow = "auto";
            switch (_opcion) {
                case 1:
                    setTitulo("Guías conciliadas de " + params.name.replace(/_/g, ' '));
                    setFileName("Guías conciliadas de " + params.name.replace(/_/g, ' '));
                    getList("conciliadas");
                    break;
                case 2:
                    setTitulo("Guías pendientes de " + params.name.replace(/_/g, ' '));
                    setFileName("Guías pendientes de " + params.name.replace(/_/g, ' '));
                    getList("pendientes");
                    break;
                case 3:
                    setTitulo("Guías devueltas por " + params.name.replace(/_/g, ' '));
                    setFileName("Guías devueltas por " + params.name.replace(/_/g, ' '));
                    getList("canceladas");
                    break;
                case 4:
                    setTitulo("Guías en garantía de " + params.name.replace(/_/g, ' '));
                    setFileName("Guías en garantía de " + params.name.replace(/_/g, ' '));
                    getList("garantías");
                    break;
                default:
                    setTitulo("Todos las guías de " + params.name.replace(/_/g, ' '));
                    setFileName("Todos las guías de " + params.name.replace(/_/g, ' '));
                    getList("todos");
                    break;
            }
        }

        const handleClic = (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (showFilter) {
                document.body.style.overflow = "auto";
                setPos({ top: 0, right: 0 });
            } else {
                document.body.style.overflow = "hidden";
                let _icono = e.target.getBoundingClientRect();

                if (media_movil) {
                    setPos({ top: (_icono.top - 8), right: 24 });
                } else {
                    setPos({ top: (_icono.top - 8), left: (_icono.left - 112) });
                }
            }
            setShowFilter(v => !v);
        }

        return (
            <>
                {showFilter && <div className="back_float" onClick={handleClic}></div>}
                <div className={showFilter ? "filter_floating _open" : "filter_floating"} style={pos}>
                    {showFilter ?
                        <>
                            <h4 className="filter_title">Filtrar por</h4>
                            <Iconos.FilterClose className="filter_icon" onClick={handleClic} />
                        </>
                        :
                        <Iconos.Filter className="filter_icon" onClick={handleClic} />}
                    {showFilter &&
                        <ul>
                            <li onClick={() => handleSelct(1)}><span>Conciliadas<Iconos.TruckCheck /></span></li>
                            <li onClick={() => handleSelct(2)}><span>Pendientes<Iconos.TruckPending /></span></li>
                            <li onClick={() => handleSelct(3)}><span>Devueltas<Iconos.TruckRemove /></span></li>
                            <li onClick={() => handleSelct(4)}><span>Garantías<Iconos.TruckPending /></span></li>
                            <li onClick={() => handleSelct(5)}><span>Todas<Iconos.TruckDelivery /></span></li>
                        </ul>}
                </div>
            </>
        )
    }

    const Row = ({ data, interline }) => {
        let fecha_entrega = new Date(data?.carrier?.fecha_entregado);
        let _estado;

        switch (parseInt(data.estado)) {
            case 1:
                _estado = "Conciliado"; // Verde
                break;
            case 2:
                _estado = "Pendiente"; // Naranja
                break;
            case 3:
                _estado = "Devuelto"; // Rojo
                break;
            case 4:
                _estado = "Conciliado pagado"; // Azul
                break;
            case 5:
                _estado = "Garantía Pagada"; // Azul aux
                break;
            case 6:
                _estado = "Garantía contraenterrega"; // Verde aux
                break;
            case 7:
                _estado = "Conciliado recibido"; // Cian
                break;
            default:
                _estado = "Sin conciliar";
                break;
        }

        const handleSelect = (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (selecToExcel) {
                setExceList(s => {
                    if (s.some(item => item.id === data.id)) {
                        return s.filter(f => f.id !== data.id);
                    } else {
                        return [...s, data.toExcel];
                    }
                });
            } else {
                if (data.carrier.conciliacion === undefined || [0, 2, 5, 6].includes(parseInt(data.estado))) {

                    setSeleccionados(s => {
                        if (s.some(item => item.id === data.id)) {
                            return s.filter(f => f.id !== data.id);
                        } else {
                            return [...s, data];
                        }
                    });
                }
            }
        }

        return (
            <Menu data={data} interline={interline}
                onUpdate={guia => {
                    setDataList(s => {
                        return s.map(item => {
                            if (item.id === data.id) {
                                return { ...item, carrier: guia.carrier };
                            } else {
                                return item;
                            }
                        });
                    });
                }}>

                {media_movil ?
                    <td onClick={handleSelect}>
                        <pre className="cont_checkbox _with_frame">
                            {selecToExcel ?
                                <input type="checkbox" name={data.id} checked={exceList.some(item => item.id === data.id)} readOnly={true} />
                                :
                                <>
                                    {data.estado == 3 ?
                                        <Iconos.Close className="_error" />
                                        :
                                        <>
                                            {(data.estado == 1 || data.estado == 4) ?
                                                <Iconos.CheckOk className="_correcto" />
                                                :
                                                <input type="checkbox" name={data.id} checked={seleccionados.some(item => item.id === data.id)} readOnly={true} />
                                            }
                                        </>
                                    }
                                </>
                            }
                            <span>Guía:</span> {data.guia} <span>Pedido:</span> {data.numero}
                        </pre>
                        {data.carrier.name ?? "Sin asignar"}
                        <br />
                        <span className="bottom_left">{_estado}</span>
                        <i>{format(fecha_entrega.getDate())}/{meses[fecha_entrega.getMonth()]}/{fecha_entrega.getFullYear()} {format(fecha_entrega.getHours())}:{format(fecha_entrega.getMinutes())}</i>
                    </td>
                    :
                    <>
                        <td className="cont_checkbox _with_frame" onClick={handleSelect}>
                            {selecToExcel ?
                                <input type="checkbox" name={data.id} checked={exceList.some(item => item.id === data.id)} readOnly={true} />
                                :
                                <>
                                    {data.estado == 3 ?
                                        <Iconos.Close className="_error" />
                                        :
                                        <>
                                            {(data.estado == 1 || data.estado == 4) ?
                                                <Iconos.CheckOk className="_correcto" />
                                                :
                                                <input type="checkbox" name={data.id} checked={seleccionados.some(item => item.id === data.id)} readOnly={true} />
                                            }
                                        </>
                                    }
                                </>
                            }
                            {data.guia}
                        </td>
                        <td>{data.numero}</td>
                        <td>{data.valor}</td>
                        <td>{format(fecha_entrega.getDate())}/{meses[fecha_entrega.getMonth()]}/{fecha_entrega.getFullYear()} {format(fecha_entrega.getHours())}:{format(fecha_entrega.getMinutes())}</td>
                        <td>{_estado}</td>
                    </>
                }
            </Menu>
        );
    }

    const AllRow = () => {
        let _data = [];
        let _footer;
        let _info;

        dataList.map((item, i) => {
            _info = JSON.parse(item.info);
            let _carrier = JSON.parse(item.carrier);
            _data.push(<Row
                key={item.id}
                interline={i % 2}
                data={{
                    ..._info,
                    carrier: _carrier,
                    estado: _carrier.conciliacion?.estado ?? 0,
                    guia: item.guia,
                    id: item.id,
                    toExcel: item
                }} />);
        });

        if (_data.length < 1) {
            _footer = <h4 className="msg_lazy_load _alone _left">{mensaje}</h4>;
        } else {
            _footer = <>
                {
                    showLoading ?
                        <button className="btn btn_lazy_load"><Iconos.Loading /></button>
                        :
                        <>
                            {showLazy ?
                                <button className="btn btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                                :
                                <h4 className="msg_lazy_load">{mensaje}</h4>
                            }
                        </>
                }
            </>;
        }

        return <>
            <table className="table">
                <tbody>
                    {media_movil ?
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>{titulo}<Filter /></th>
                        </tr>
                        :
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th className="cont_checkbox _with_frame" onClick={() => {
                                if (selecToExcel) {
                                    setExceList(() => {
                                        if (exceList.length == dataList.length) {
                                            return [];
                                        } else {
                                            return dataList;
                                        }
                                    });
                                } else {
                                    setSeleccionados(() => {
                                        if (seleccionados.length > 0) {
                                            return [];
                                        } else {
                                            return dataList.filter((item) => {
                                                let _carrier = JSON.parse(item.carrier);
                                                if (_carrier.conciliacion === undefined || [0, 2, 5, 6].includes(parseInt(_carrier?.conciliacion?.estado))) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            });
                                        }
                                    });
                                }
                            }}>
                                <input type="checkbox" name="all" checked={selecToExcel ? exceList.length == dataList.length : seleccionados.length > 0} readOnly={true} />
                                Guía<Filter />
                            </th>
                            <th>Pedido</th>
                            <th>Valor</th>
                            <th>Fecha entregado</th>
                            <th>Estado</th>
                        </tr>
                    }
                    {_data}
                </tbody>
            </table>
            {_footer}
        </>
    }

    const setCancelations = async () => {
        setShowLoading(true);
        let _guias = seleccionados.map(p => {
            return {
                id: p.id,
                signature: p.signature,
                carrier: p.carrier
            };
        });

        let _data = await api.fetchJson({
            url: "set-devolution",
            data: {
                info: JSON.stringify({
                    state_int: 3,
                    state_old: 0,
                    state_name: "Devuelto por transportadora",
                    guias: _guias
                })
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                getList(currentQuery);
                setSeleccionados([]);
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-guides-list",
            data: {
                task: currentQuery,
                criterio: criterio,
                limit: 20,
                offset: dataList.length,
                carrying: params.carrying
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setDataList(_packs);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleCancelations = async (e) => {
        Confirm("¿Estás seguro de marcar estas guías como devueltas?", resp => {
            if (resp) {
                setCancelations();
            }
        });
    }

    const handleSearch = async (_val) => {
        _val = _val.toLowerCase();
        setCriterio(_val);
        setMensaje(_val === "" ? "Cargando guías..." : "Buscando guías con “" + _val + "”...");

        setShowLoading(true);
        setCurrentQuery(_val === "" ? "todos" : "search");
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-guides-list",
            data: {
                task: _val === "" ? "todos" : "search",
                criterio: _val,
                limit: 20,
                offset: 0,
                carrying: params.carrying
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        if (_data.data.length > 0) {
            setMensaje(`¡La transportadora ${params.name.replace(/_/g, ' ')} no tiene más guías con “ ${_val} “!`);
        } else if (_val === "") {
            setMensaje(`¡La transportadora ${params.name.replace(/_/g, ' ')} no tiene guías!`);
        } else {
            setMensaje(`¡La transportadora ${params.name.replace(/_/g, ' ')} no tiene guías con “ ${_val} “!`);
        }

        setShowLoading(false);
    }

    const getList = async (_c_query = "todos") => {
        setShowLoading(true);
        setCurrentQuery(_c_query);
        setMensaje(_c_query === "todos" ? "Cargando guías..." : "Cargando guías " + _c_query + "...");

        setSeleccionados([]);
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-guides-list",
            data: {
                task: _c_query,
                limit: 20,
                offset: 0,
                carrying: params.carrying
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        if (_data.data.length > 0) {
            setMensaje(`¡La transportadora ${params.name.replace(/_/g, ' ')} no tiene más guías!`);
        } else if (_c_query === "todos") {
            setMensaje(`¡La transportadora ${params.name.replace(/_/g, ' ')} no tiene guías!`);
        } else {
            setMensaje(`¡La transportadora ${params.name.replace(/_/g, ' ')} no tiene guías ${_c_query}!`);
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        setTitle("Pedidos de " + params.name.replace(/_/g, ' '));

        funciones.current.validaSesion("concilios_ver").then(response => {
            if (response) {
                setPermission(true);
                getList();
            } else {
                setPermission(false);
                setShowLoading(false);
            }
        });
    }, [params.carrying, location.pathname]);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>
            <div className="head_bar_sticky">
                <h2>{titulo}</h2>
                <div className="in_row">

                    {selecToExcel ?
                        <>
                            {exceList.length < 1 ?
                                <h3>No hay guías seleccionadas</h3>
                                :
                                <h3>{exceList.length} guía{exceList.length == 1 ? "" : "s"} seleccionada{exceList.length == 1 ? "" : "s"}</h3>
                            }
                            <HojaDeCalculo />
                        </>
                        :
                        <>
                            {seleccionados.length < 1 ?
                                <h3>No hay guías seleccionadas</h3>
                                :
                                <h3>{seleccionados.length} guía{seleccionados.length == 1 ? "" : "s"} seleccionada{seleccionados.length == 1 ? "" : "s"}</h3>
                            }
                            {seleccionados.length > 0 ?
                                <button className="btn btn_with_icon _left" onClick={handleCancelations}><Iconos.TruckRemove />Marcar devolución </button>
                                :
                                <button className="btn btn_with_icon _left unable"><Iconos.TruckRemove />Marcar devolución </button>
                            }
                        </>
                    }

                    <div className="checkbox_top_right">
                        <input type="checkbox" defaultChecked={selecToExcel} onChange={(e) => setSelecToExcel(e.target.checked)} />
                        {selecToExcel ?
                            <Iconos.TruckRemove />
                            :
                            <Iconos.ExcelDownload />
                        }
                    </div>
                </div>
            </div>

            <div className="head_bar with_margin">
                <div className="search_bar">
                    <InputSearch type="text" placeholder="Buscar por guía o pedido"
                        value={criterio} onChange={handleSearch} />
                </div>
            </div>

            <AllRow />

            <div className="content_btns in_movil">
                <NavLink
                    to={`/conciliar-guias/${params.carrying}/${params.name}`}
                    className="btn btn_add_to_list _left">
                    Ir a conciliar guías <Iconos.TruckExcelUpload />
                </NavLink>

                <button className="btn btn_add_to_list" onClick={() => navigate("/conciliaciones", { state: { rol: 6 } })}>Cambiar transportadora <Iconos.Truck /></button>

                <NavLink
                    to={`/conciliaciones/${params.carrying}/${params.name}`}
                    className="btn btn_add_to_list _right">
                    Ir a conciliaciones <Iconos.TruckCheck />
                </NavLink>
            </div>
            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(Devolutions);