import * as React from "react";
import withRouter from "services/withRouter";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const getDPI = () => {
    // Crea un elemento invisible para medir
    const dpiElement = document.createElement('div');
    dpiElement.style.width = '1in'; // 1 pulgada en CSS
    dpiElement.style.height = '1in'; // 1 pulgada en CSS
    dpiElement.style.position = 'absolute';
    dpiElement.style.left = '-100%'; // Posiciona fuera de la vista
    document.body.appendChild(dpiElement);

    // Obtiene el tamaño en píxeles
    const dpi = dpiElement.offsetWidth;

    // Elimina el elemento después de medir
    document.body.removeChild(dpiElement);

    return dpi;
}

const PrintingDispatch = ({ data, carrier, close }) => {
    const dpi = getDPI();
    const widthInMM = 58;  // Ancho en milímetros
    const heightInMM = 265;  // Alto en milímetros
    const widthInInches = widthInMM / 25.4;  // Ancho en pulgadas
    const heightInInches = heightInMM / 25.4;  // Alto en pulgadas
    const width = widthInInches * dpi;
    const height = heightInInches * dpi;
    const [fecha] = React.useState(new Date());


    React.useEffect(() => {
        const handleBeforePrint = () => {
            log.w("Se activó la impresión");
            const printStyles = `
                @page {
                    size: ${width}px ${height + 12}px;
                }
                @media print {
                      body {
                        width: ${width}px;
                        height: ${height + 12}px;
                        padding: 0;
                        margin: 0;
                        background-color: rgb(255, 255, 255) !important;
                        color: rgb(20, 20, 20);
                      }

                      header,
                      footer,
                      .head_bar,
                      .content_btns {
                        display: none !important;
                      }

                      .wrapper {
                        width: 100% !important;
                        padding: 0;
                        margin: 0;
                        background-color: rgb(255, 255, 255) !important;

                        .container {
                          display: block;
                          width: 100% !important;
                          margin: 0 !important;
                          padding: 0 !important;
                          background-color: rgb(255, 255, 255) !important;
                          color: rgb(20, 20, 20) !important;

                          .content_to_print {
                            min-width: ${width}px !important;
                            width: 100% !important;
                            page-break-after: always;
                          }
                        }
                      }
                    }
            `;

            if (document.getElementById("printGuideStyles")) {
                document.getElementById("printGuideStyles").remove();
            }
            if (document.getElementById("printOrdersStyles")) {
                document.getElementById("printOrdersStyles").remove();
            }

            if (!document.getElementById("printDispatchStyles")) {
                const styleSheet = document.createElement("style");
                styleSheet.id = "printDispatchStyles";
                styleSheet.innerHTML = printStyles;
                document.head.appendChild(styleSheet);
                log.d("Se agrego el estilo de impresión");
            } else {
                log.d("El estilo de impresión ya existe");
            }
        }

        const handleAfterPrint = () => {
            close();
        }

        window.addEventListener('beforeprint', handleBeforePrint);
        window.addEventListener('afterprint', handleAfterPrint);

        setTimeout(() => {
            window.print();
        }, 600);

        return () => {
            window.removeEventListener('beforeprint', handleBeforePrint);
            window.removeEventListener('afterprint', handleAfterPrint);
        };
    }, []);

    return (
        <div className="marco_print">
            <div className="content_to_print" style={{ width: width + "px" }}>
                <h1>Despacho entregado</h1>
                <i>{format(fecha.getDate())} de {meses[fecha.getMonth()]} del {fecha.getFullYear()}</i>
                <h2>{carrier}</h2>

                <table className="table">
                    <tbody>
                        <tr className="table_title">
                            <th></th>
                            <th>Guía</th>
                            <th>Pedido</th>
                        </tr>

                        {data.map((item, i) => <tr key={item.id} className={(i % 2) === 1 ? "table_row bkg_inter_line" : "table_row"}>
                            <td>{i + 1}</td>
                            <td>{item.guia}</td>
                            <td>{item.pedidos}</td>
                            <td className="td_menu"></td>
                        </tr>)}
                    </tbody>
                </table>

                <div className="cont_firma">
                    <h4>{carrier}</h4>
                    <i>{format(fecha.getDate())} de {meses[fecha.getMonth()]} del {fecha.getFullYear()}</i>
                    <p>{data.length == 1 ? data.length + " pedido" : data.length + " pedidos"}</p>
                    <p className="firma">Firma: <sub> <hr /></sub></p>
                </div>
            </div>
        </div>
    )
}

export default withRouter(PrintingDispatch);