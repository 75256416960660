import * as React from "react";
import { InputList, InputWithIcon, SelectWithIcon } from "shared/Components";
import { Alert, alerta, alertaRemove, ConfirmaRojo } from "shared/Alert";
import { RequirePermissions } from "shared/RequirePermissions";
import PrintingDispatch from "./PrintingDispatch";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { NavLink } from "react-router-dom";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import * as Iconos from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-useless-escape: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

// eslint-disable-next-line
const defaultList = [
    { id: 1, guia: "Guia 1", pedidos: "Pedidos 1", carrier: "Carrier 1", fecha_creado: "2022-01-01" },
    { id: 2, guia: "Guia 2", pedidos: "Pedidos 2", carrier: "Carrier 2", fecha_creado: "2022-01-02" },
    { id: 3, guia: "Guia 3", pedidos: "Pedidos 3", carrier: "Carrier 3", fecha_creado: "2022-01-03" },
    { id: 4, guia: "Guia 4", pedidos: "Pedidos 4", carrier: "Carrier 4", fecha_creado: "2022-01-04" },
    { id: 5, guia: "Guia 5", pedidos: "Pedidos 5", carrier: "Carrier 5", fecha_creado: "2022-01-05" },
    { id: 6, guia: "Guia 6", pedidos: "Pedidos 6", carrier: "Carrier 6", fecha_creado: "2022-01-06" },
    { id: 7, guia: "Guia 7", pedidos: "Pedidos 7", carrier: "Carrier 7", fecha_creado: "2022-01-07" },
    { id: 8, guia: "Guia 8", pedidos: "Pedidos 8", carrier: "Carrier 8", fecha_creado: "2022-01-08" },
    { id: 9, guia: "Guia 9", pedidos: "Pedidos 9", carrier: "Carrier 9", fecha_creado: "2022-01-09" },
    { id: 10, guia: "Guia 10", pedidos: "Pedidos 10", carrier: "Carrier 10", fecha_creado: "2022-01-10" },
    { id: 11, guia: "Guia 11", pedidos: "Pedidos 11", carrier: "Carrier 11", fecha_creado: "2022-01-11" },
    { id: 12, guia: "Guia 12", pedidos: "Pedidos 12", carrier: "Carrier 12", fecha_creado: "2022-01-12" },
    { id: 13, guia: "Guia 13", pedidos: "Pedidos 13", carrier: "Carrier 13", fecha_creado: "2022-01-13" },
    { id: 14, guia: "Guia 14", pedidos: "Pedidos 14", carrier: "Carrier 14", fecha_creado: "2022-01-14" },
    { id: 15, guia: "Guia 15", pedidos: "Pedidos 15", carrier: "Carrier 15", fecha_creado: "2022-01-15" },
    { id: 16, guia: "Guia 16", pedidos: "Pedidos 16", carrier: "Carrier 16", fecha_creado: "2022-01-16" },
    { id: 17, guia: "Guia 17", pedidos: "Pedidos 17", carrier: "Carrier 17", fecha_creado: "2022-01-17" },
    { id: 18, guia: "Guia 18", pedidos: "Pedidos 18", carrier: "Carrier 18", fecha_creado: "2022-01-18" },
    { id: 19, guia: "Guia 19", pedidos: "Pedidos 19", carrier: "Carrier 19", fecha_creado: "2022-01-19" },
    { id: 20, guia: "Guia 20", pedidos: "Pedidos 20", carrier: "Carrier 20", fecha_creado: "2022-01-20" }
];

const DeliveryDispatch = (router) => {
    const { navigate, location } = router;
    const { setTitle, media_movil, setDataUser } = useMedia();
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [permission, setPermission] = React.useState(false);
    const [carrierInfo, setCarrierInfo] = React.useState({});
    const [currentList, setCurrentList] = React.useState([]);
    const [carrierList, setCarrierList] = React.useState([]);
    const [domicsList, setDomicsList] = React.useState([]);
    const [modalView, setModalView] = React.useState(null);
    const [carrier, setCarrier] = React.useState("");
    const [domici, setDomici] = React.useState("");
    const [guia, setGuia] = React.useState("");

    const carrierRef = React.useRef(null);
    const domiciRef = React.useRef(null);
    const guiaRef = React.useRef(null);

    const handlePrint = (e) => {
        e.preventDefault();
        setModalView(<PrintingDispatch data={currentList} carrier={carrierInfo.name} close={() => setModalView(null)} />);
    }

    const handleDomiciliary = (_value) => {
        alertaRemove(domiciRef.current);
        setDomici(_value);

        let item = domicsList.filter(({ info }) => {
            let _info = JSON.parse(info);
            let _nombres = _info.nombre + " " + _info.apellido;
            return _nombres.trim() === _value;
        });
        item = item[0];

        if (typeof item === "object") {
            setCarrierInfo({ id: item.id, name: _value });
            guiaRef.current.focus();
        } else {
            setCarrierInfo({});
            alerta(domiciRef.current, "¡El domiciliario es invalido!");
            domiciRef.current.focus();
        }
    }

    const handleCarrier = (_value) => {
        alertaRemove(carrierRef.current);

        if (_value === "Agregar nuevo") {
            navigate("/crear-usuario", { state: { rol: 6 } });
        } else if (_value === "Domiciliario") {
            setCarrier(_value);
            getDomics();
        } else {
            let item = carrierList.filter(({ info }) => {
                let _info = JSON.parse(info);
                let _nombres = _info.nombre + " " + _info.apellido;
                return _nombres.trim() === _value;
            });
            item = item[0];

            if (typeof item === "object") {
                setCarrier(_value);
                setCarrierInfo({ id: item.id, name: _value });
                guiaRef.current.focus();
            } else {
                setCarrier("");
                setCarrierInfo({});
                alerta(carrierRef.current, "¡La transportadora es invalida!");
                carrierRef.current.focus();
            }
        }
    }

    const handleInputGuia = (_value) => {
        alertaRemove(guiaRef.current);
        setGuia(_value);
        setTimeout(() => {
            if (guiaRef.current.value.length === _value.length) {
                validate(_value);
            }
        }, 1000);
    }

    const saveDispatch = async (_guia) => {
        setModalView(<Loading state={true} />);

        let _data = await api.fetchJson({
            url: "delivery-dispatch",
            data: {
                task: "??",
                info: {
                    guia: _guia,
                    carrier: carrierInfo,
                    state_int: 1,
                    state_name: "Entregado a " + carrierInfo.name,
                }
            }
        });


        if (_data.response == 1) {
            let _info = _data.data[0]
            setTimeout(() => {
                setCurrentList(l => {
                    return [...l, {
                        id: l.length + 1,
                        old: _info.old,
                        guia: _info.guia,
                        pedidos: _info.pedidos,
                        carrier: _info.carrier,
                        fecha_creado: _info.fecha_creado
                    }];
                });
                setGuia("");
                guiaRef.current.focus();
                setModalView(null);
            }, 200);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
            setModalView(null);
        } else {
            Alert(_data.msg, "warning");
            setModalView(null);
        }
    }

    const validaCarrier = async (_guia) => {
        setModalView(<Loading state={true} />);

        let _data = await api.fetchJson({
            url: "delivery-validate-carrier",
            data: {
                task: "??",
                info: {
                    guia: _guia,
                    carrier: { ...carrierInfo, normalize: carrierInfo.name.toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "") },
                }
            }
        });


        if (_data.response == 1) {
            saveDispatch(_guia);
        } else if (_data.response == 2 || _data.response == 3) {
            setModalView(null);
            ConfirmaRojo(_data.msg, async (response) => {
                if (response) {
                    saveDispatch(_guia);
                }
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
            setModalView(null);
        } else {
            Alert(_data.msg, "warning");
            setModalView(null);
        }
    }

    const validate = (_guia) => {
        alertaRemove(guiaRef.current);
        alertaRemove(carrierRef.current);
        carrier === "Domiciliario" && alertaRemove(domiciRef.current);

        if (carrierInfo.id === undefined) {
            if (carrier === "Domiciliario") {
                alerta(domiciRef.current, "¡Debes ingresar un domocililario!");
                domiciRef.current.focus();
            } else {
                alerta(carrierRef.current, "¡Debes seleccionar la tranportadora!");
                carrierRef.current.focus();
            }

            return false;
        }

        if (_guia == null || _guia.length < 1) {
            alerta(guiaRef.current, "¡Debes ingresar el número de la guía!");
            guiaRef.current.focus();
            return false;
        }

        validaCarrier(_guia);
    }

    const getDomics = async () => {
        setModalView(<Loading state={true} />);

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                task: "domiciliarios"
            }
        });

        if (_data.response == 1) {
            setDomicsList(_data.data);
            // domiciRef.current.focus();
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setModalView(null);
    }

    const getCarriers = async () => {
        setModalView(<Loading state={true} />);

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                task: "carriers"
            }
        });

        if (_data.response == 1) {
            setCarrierList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setModalView(null);
    }

    React.useEffect(() => {
        setCurrentList([]);
        // setCurrentList(defaultList);
    }, [carrierInfo]);


    React.useEffect(() => {

        if (location.state && location.state.data) {
            let _data = location.state.data;

            setGuia(_data.guia);
        }
    }, [location.state]);

    React.useEffect(() => {
        setTitle("Entregar despachos");

        funciones.current.validaSesion("despachos_entregar").then(response => {
            if (response) {
                setPermission(true);
                getCarriers();
            } else {
                setPermission(false);
                setModalView(null);
            }
        });
    }, []);

    return (
        <RequirePermissions permission={permission} loading={true}>

            <div className="head_bar">
                <h2 className="head_bar-title">Entregar despachos</h2>
            </div>

            {carrier === "Domiciliario" && <div className="content_primary">
                <InputList
                    ref={domiciRef}
                    title="Domiciliario"
                    Icono={Iconos.Moped}
                    value={domici}
                    onChange={handleDomiciliary}
                    list={domicsList.map(({ info }) => {
                        let _info = JSON.parse(info);
                        let _nombres = _info.nombre + " " + _info.apellido;
                        return _nombres.trim();
                    })} />
            </div>}

            <div className="content_primary">

                <SelectWithIcon
                    ref={carrierRef}
                    title="Transportadora"
                    Icono={Iconos.TruckFast}
                    value={carrier}
                    onChange={handleCarrier} >
                    <option value="">Selecciona transportadora</option>
                    {carrierList.map(item => {
                        let _info = JSON.parse(item.info);
                        return <option key={item.id}>{_info.nombre} {_info.apellido}</option>;
                    })}
                    <option>Domiciliario</option>
                    <option>Agregar nuevo</option>
                </SelectWithIcon>

                <InputWithIcon
                    ref={guiaRef}
                    type="text"
                    title="Número de guía"
                    value={guia}
                    readOnly={carrierInfo.id === undefined}
                    className={carrierInfo.id === undefined ? "unable" : ""}
                    onChange={handleInputGuia} />
            </div>

            {(location.state.data && location.state.data.estado == 0 && location.state.data.guia === guia) &&
                <div className="content_btns _with_margin">
                    <button type="button" className="btn btn_primary" onClick={() => validate(guia)}>Entregar</button>
                </div>}


            {currentList.length > 0 && <>
                <p className="count_list"><b>{currentList.length}</b> entregado{currentList.length > 1 ? "s" : ""} a <b>{carrierInfo.name}</b></p>
                <table className="table">
                    <tbody>
                        {media_movil ?
                            <tr className="table_title">
                                <th>Prueba</th>
                            </tr>
                            :
                            <tr className="table_title">
                                <th></th>
                                <th>Guía</th>
                                <th>Pedido</th>
                                <th>Transportadora</th>
                                <th>Fecha creado</th>
                            </tr>
                        }
                        {currentList.map((item, i) => {
                            let fecha_creado = new Date(item.fecha_creado);
                            return <tr key={item.id} className={(i % 2) === 1 ? "table_row bkg_inter_line" : "table_row"}>
                                {media_movil ?
                                    <td>
                                        <pre><span>Guía:</span> {item.guia}  <span>Pedido:</span> {item.pedidos}</pre>
                                        {item.carrier}
                                        <i>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</i>
                                    </td>
                                    :
                                    <>
                                        <td>{i + 1}</td>
                                        <td>{item.guia}</td>
                                        <td>{item.pedidos}</td>
                                        <td>{item.carrier}</td>
                                        <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                                        <td className="td_menu"></td>
                                    </>
                                }
                            </tr>;
                        })}
                    </tbody>
                </table>
            </>}

            <div className="content_btns">
                {carrierInfo.id === undefined ?
                    <button type="button" className="btn link_to_btn _left _disabled">Imprimir</button>
                    :
                    <button type="button" className="btn link_to_btn _left" onClick={handlePrint}>Imprimir</button>
                }

                <NavLink
                    to="/despachos"
                    className="btn link_to_btn _right">
                    Ver todos los despachos
                </NavLink>
            </div>
            {modalView}
        </RequirePermissions>
    )
}

export default withRouter(DeliveryDispatch);