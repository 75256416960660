import * as React from "react";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { Funciones } from "services/funciones";
import { TextArea } from "shared/Components";
import { useParams } from "react-router-dom";
import MovePopUp from "shared/MovePopUp";
import { useMedia } from "hooks";
import * as Iconos from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Menu = (params) => {
    const { navigate, data, interline, children, reload, current, inRow = false } = params;
    const { setDataUser } = useMedia();
    const [clases, setClases] = React.useState(interline == 0 ? "table_row" : "table_row bkg_inter_line");
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [modalView, setModalView] = React.useState(null);
    const path_params = useParams();


    const goOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setModalView(<PopUpDetalle />);
    }

    const goEdit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        navigate("/editar-venta/" + path_params.local, { state: { data: { ...data, tipo: "Editar" } } });
    }

    const goDuplica = (e) => {
        e.preventDefault();
        e.stopPropagation();

        navigate("/duplicar-venta/" + path_params.local, { state: { data: { ...data, tipo: "Duplicar" } } });
    }

    const goPrint = (e) => {
        e.preventDefault();
        e.stopPropagation();

        navigate("/tirilla-impresion/" + path_params.local, { state: { data: [data] } });
    }

    const goCancel = (parent) => {
        setModalView(<PopUpCancel parent={parent} />);
    }

    const stopClick = (e) => {
        // e.preventDefault(); Se comenta esta linea para que funcione el input[type="file"]
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        setModalView(null);
    }

    const BtnEdit = () => {
        let _btn = <li className="unable"><Iconos.Edit /> <span>Editar</span></li>;
        let _isOpen = data.metafields.filter(meta => meta.key === "reabierto")[0]?.value ?? "";

        if (funciones.current.validaPermisos("locales_admin") && (data.fulfillment_status.toLowerCase() !== "fulfilled" || _isOpen === "reabierto")) {
            _btn = <li onClick={goEdit}><Iconos.Edit /> <span>Editar</span></li>;
        }

        return _btn;
    }

    const BtnDuplica = () => {
        let _btn = <li className="unable"><Iconos.PedidoDuplica /> <span>Duplicar</span></li>;

        if (funciones.current.validaPermisos("locales_admin")) {
            _btn = <li onClick={goDuplica}><Iconos.PedidoDuplica /> <span>Duplicar</span></li>;
        }

        return _btn;
    }

    const BtnPrint = () => {
        let _btn = <li className="unable"><Iconos.PrinterOutline /> <span>Imprimir tirilla</span></li>;

        if (funciones.current.validaPermisos("locales_admin") && data.note_attributes[0]?.name === "Estado" && data.note_attributes[0]?.value === "para imprimir") {
            _btn = <li onClick={goPrint}><Iconos.PrinterOutline /> <span>Imprimir tirilla</span></li>;
        }

        if (funciones.current.validaPermisos("locales_admin") && data.fulfillment_status.toLowerCase() === "fulfilled") {
            _btn = <li onClick={goPrint}><Iconos.PrinterOutline /> <span>Imprimir tirilla</span></li>;
        }

        return _btn;
    }

    const BtnDelete = ({ parent }) => {
        let _btn = <li className="unable"><Iconos.PedidosCancelado /> <span>Cancelar</span></li>;

        if (funciones.current.validaPermisos("locales_admin") && data.fulfillment_status.toLowerCase() !== "fulfilled") {
            _btn = <li onClick={e => {
                hiddenMenu(e);
                goCancel(parent);
            }}><Iconos.PedidosCancelado /> <span>Cancelar</span></li>;
        }

        return _btn;
    }

    const AllButtons = ({ e }) => {
        const [pos, setPos] = React.useState({ top: 0, left: 0 });
        const menuRef = React.useRef();

        React.useEffect(() => {
            document.body.style.overflow = "hidden";
            if (inRow) {
                setPos(funciones.current.getPosInRow(e, menuRef.current));
            } else {
                setPos(funciones.current.getPosInDisplay(e, menuRef.current));
            }
            setTimeout(() => {
                menuRef.current.style.opacity = 1;
            }, 100);
        }, []);

        return (
            <>
                <div className="back_float" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                <nav className="menu" style={{ ...pos, width: 180 }} ref={menuRef}>
                    <ul>
                        {inRow ?
                            <li onClick={goOpen}><Iconos.OpenIn /> <span><strong>Detalle</strong></span></li>
                            :
                            <li className="unable"><Iconos.OpenIn /> <span><strong>...</strong></span></li>
                        }
                        <BtnEdit />
                        <BtnDuplica />
                        <hr />
                        <BtnPrint />
                        <hr />
                        <BtnDelete parent={pos} />
                    </ul>
                </nav>
            </>)
    }

    const PopUpCancel = ({ parent }) => {
        const [showLoading, setShowLoading] = React.useState(false);
        const [nota, setNota] = React.useState("");
        const menuRef = React.useRef();
        const notaRef = React.useRef();

        const handleCancel = async () => {
            let _data = await api.fetchJson({
                url: "shopify-cancel-order",
                data: {
                    ...data,
                    order_id: data.id,
                    nota: nota
                }
            });

            if (_data.response == 1) {
                Alert(_data.msg, "ok", () => reload());
                setModalView(null);
            } else if (_data.response == -2) {
                Alert(_data.msg, "warning", () => navigate("/sign-in"));
            } else {
                Alert(_data.msg, "warning");
            }

            setShowLoading(false);
        }

        const validate = (e) => {
            e.preventDefault();
            alertaRemove(notaRef.current);

            if (nota.length < 1) {
                alerta(notaRef.current, "¡Debes ingresar el motivo de la cancelación!");
                notaRef.current.focus();
                return false;
            }

            setShowLoading(true);
            handleCancel();
        }

        return <MovePopUp
            parent={{ ...parent, width: 250 }}
            close={() => setModalView(null)}
            title="Cancelar pedido">

            <div className="content_primary _on_popup" ref={menuRef}>

                <TextArea
                    ref={notaRef}
                    title="Motivo de cancelación"
                    value={nota}
                    onChange={v => { alertaRemove(notaRef.current); setNota(v) }} />
            </div>


            <div className="content_btns _without_margin">
                {showLoading ?
                    <button type="button" className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <button type="button" className="btn btn_primary" onClick={validate}>Guardar</button>
                }
            </div>
        </MovePopUp>;
    }

    const PopUpDetalle = () => {
        const [infoCustomer, setInfoCustomer] = React.useState({});
        const [loadInfo, setLoadInfo] = React.useState(true);
        const [tags, setTags] = React.useState([]);
        const { media_movil } = useMedia();
        const products = data.line_items;
        const total_articulos = data.total_articulos + " " + (data.total_articulos > 1 ? "artículos" : "articulo");
        let _tag = data.tags.includes("CONTRAENTREGA") ? "CONTRAENTREGA" : null;

        const getCustomer = async () => {
            setLoadInfo(true);

            let _data = await api.fetchJson({
                url: "shopify-get-customer",
                data: {
                    id: data.customer.id,
                    tipo: "id"
                }
            });

            if (_data.response == 1) {
                setInfoCustomer(_data.data);
            } else if (_data.response == -2) {
                Alert(_data.msg, "warning", () => navigate("/sign-in"));
            } else {
                Alert(_data.msg, "warning");
            }

            setLoadInfo(false);
        }

        React.useEffect(() => {
            document.body.style.overflow = "hidden";
            let _tags_list = [];

            if (!infoCustomer.id) {
                getCustomer();
            }

            data.tags
                .replace(/,\s*local\.[A-Za-z0-9=]+|local\.[A-Za-z0-9=]+,\s*|local\.[A-Za-z0-9=]+/g, "").replace(/,,/g, ",")
                .split(",").forEach(item => {
                    if (item.trim().length > 0) {
                        _tags_list.push(item.trim());
                    }
                });

            setTags(_tags_list);
        }, []);

        return <MovePopUp
            parent={{ top: media_movil ? 80 : 100, left: media_movil ? "5%" : "calc(50% - 275px)", width: media_movil ? "90%" : 550 }}
            className="popup_window height_fixed"
            close={() => {
                document.body.style.overflow = "auto";
                setModalView(null);
            }}
            title={"Detalle del pedido " + data.name}>

            <div className="content_primary _pedido_detalle">
                <div className="in_column" style={{ width: media_movil ? "100%" : 320, maxWidth: media_movil ? "100%" : 320 }}>
                    {media_movil && <button className="btn btn_pequenio" onClick={() => navigate("/factura", { state: { data: [data] } })}><span>factura</span></button>}

                    <div className="info_card">
                        <h5 style={{ marginBottom: "0.6rem" }}>Artículos <sup>{data.fulfillment_status.toLowerCase() === "fulfilled" ? "Preparado" : "No preparado"}</sup></h5>
                        {products.map((item, i) => {
                            let _precio = parseFloat(item.price);
                            let _valor = funciones.current.formatPrice(_precio).format;
                            let _total = funciones.current.formatPrice(_precio * item.quantity).format;
                            return <p key={i} className="info_product"><b>{_valor} x {item.quantity} = {_total}</b> - <span>{item.name}</span></p>;
                        })}
                    </div>

                    <div className="info_card">
                        <h5 style={{ marginBottom: "1rem" }}>Pago {_tag && <b className="tag">{_tag}</b>} <sup>{data.financial_status.toLowerCase() === "pending" ? "Pendiente" : data.financial_status.toLowerCase() === "paid" ? "Pagado" : data.financial_status.toLowerCase() === "partially_paid" ? "Pago parcial" : data.financial_status}</sup></h5>
                        {data?.metafields?.filter(meta => meta.key === "Metodo de pago")[0] && <p className="info_price"><span className="left">Método de pago</span> <span className="right"><b>{data?.metafields?.filter(meta => meta.key === "Metodo de pago")[0]?.value ?? ""}</b></span></p>}
                        {data?.metafields?.filter(meta => meta.key === "efectivo")[0] && <p className="info_price"><span className="left">Efectivo</span> <span className="right">${funciones.current.formatPrice(data?.metafields?.filter(meta => meta.key === "efectivo")[0]?.value ?? 0).format}</span></p>}
                        {data?.metafields?.filter(meta => meta.key === "transferencia")[0] && <p className="info_price"><span className="left">Transferencia</span> <span className="right">${funciones.current.formatPrice(data?.metafields?.filter(meta => meta.key === "transferencia")[0]?.value ?? 0).format}</span></p>}
                        {data?.metafields?.filter(meta => meta.key === "Metodo de pago")[0] && <p style={{ height: "0.5rem", display: "block" }}></p>}
                        <p className="info_price"><span className="left">Subtotal </span> <span className="center">{total_articulos}</span>  <span className="right">${funciones.current.formatPrice(data.subtotal_price.toString().replace(".", ",")).format}</span></p>
                        {/* <p className="info_price"><span className="left">Envio </span> <span className="right">${funciones.current.formatPrice(data.total_shipping_price.toString().replace(".", ",")).format}</span></p> */}
                        <p className="info_price"><span className="left">Descuento</span> <span className="right">${funciones.current.formatPrice(data.total_discounts.toString().replace(".", ",")).format}</span></p>
                        <p className="info_price _total"><span className="left">Total</span> <span className="right">${funciones.current.formatPrice(data.total_price.toString().replace(".", ",")).format}</span></p>
                    </div>

                    <div className="info_card">
                        <h5>Etiquetas</h5>
                        <p className="cont_tags">
                            {tags.map((tag, index) => {
                                return <span key={index} className="tag">{tag}</span>
                            })}
                        </p>
                    </div>
                </div>

                <div className="in_column" style={{ width: media_movil ? "100%" : 200, maxWidth: media_movil ? "100%" : 200 }}>
                    {((data.note_attributes[0]?.name === "Estado" && data.note_attributes[0]?.value === "para imprimir") || data.fulfillment_status.toLowerCase() === "fulfilled") && <button className="btn btn_pequenio" onClick={goPrint}><span>Imprimir tirilla</span></button>}

                    <div className="info_card">
                        <h5>Notas</h5>
                        <p className="info_nota">{data.note}</p>
                        <h5>Detalles adicionales</h5>
                        <p className="info_attributes">
                            {data.note_attributes.map((nota, index) => <React.Fragment key={index}>
                                <b>{nota.name}</b>
                                <span>{nota.value}</span>
                            </React.Fragment>)}
                        </p>
                    </div>

                    <div className="info_card">
                        <h5>Cliente {loadInfo && <Iconos.Loading className="icon_loading" />}</h5>
                        <p className="info_name">{data.customer.first_name} {data.customer.last_name} </p>
                        {infoCustomer?.default_address?.company && <p className="info_document"><b>Documento:</b> {infoCustomer?.default_address?.company}</p>}
                        {infoCustomer?.email && <i className="info_email">{infoCustomer?.email}</i>}
                        {infoCustomer?.orders_count && <p className="info_orders">{infoCustomer?.orders_count} {infoCustomer?.orders_count > 1 ? "pedidos" : "pedido"}</p>}

                        <h5>Dirección</h5>
                        {infoCustomer?.default_address && <p className="info_address">{infoCustomer?.default_address?.address1} {infoCustomer?.default_address?.province}, {infoCustomer?.default_address?.city}</p>}
                        {infoCustomer?.default_address?.phone && <i className="info_phone"><b>Teléfono:</b> {infoCustomer?.default_address?.phone}</i>}
                        {infoCustomer?.default_address?.address2 && <i className="info_phone"><b>Teléfono 2:</b> {infoCustomer?.default_address?.address2}</i>}
                    </div>
                </div>
            </div>
        </MovePopUp>;
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setModalView(<AllButtons e={e} />);
    }

    React.useEffect(() => {
        let _isOpen = data.metafields.filter(meta => meta.key === "reabierto")[0]?.value ?? "";
        if (_isOpen === "reabierto") {
            setClases(c => c + " bkg_reabierto");
        }

        if (data.metafields.filter(meta => meta.key === "Estado")[0]?.value === "por validar") {
            setClases(c => c + " bkg_por_validar");
        }
    }, [data]);

    React.useEffect(() => {
        if (inRow) {
            current === data.id && setClases(c => c + " bkg_select");
        }
    }, [current]);

    return (
        <>
            {inRow ?
                <tr className={clases}
                    onDoubleClick={goOpen}
                    onContextMenu={handleContextMenu}>

                    {children}

                    <td className="td_menu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                        {modalView}
                    </td>
                </tr>
                :
                <>
                    <div className="container _detalle" onContextMenu={handleContextMenu}>

                        {children}
                        {modalView}
                    </div>
                </>}
        </>
    )
}

export default Menu;